import React, { ReactNode } from "react";
import "~/styles/global.css";
import "~/features/marketing/general-page.css";

type LoginErrorPageProps = {
  readonly superTitle: string;
  readonly title: string;
  readonly children: ReactNode;
};

function LoginErrorPage({ superTitle, title, children }: LoginErrorPageProps) {
  return (
    <div className="container general-page">
      <h2>{superTitle}</h2>
      <h3>{title}</h3>
      {children}
    </div>
  );
}

export default LoginErrorPage;
